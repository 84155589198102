import React, { useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


import 'aos/dist/aos.css'; // Importa los estilos de AOS
import AOS from 'aos';

import emailjs from 'emailjs-com'; // Importar EmailJS


function App() {

  // Estado para controlar si el menú está abierto o cerrado
  const [isOpen, setIsOpen] = useState(false);

  // Función para alternar el menú
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };



  AOS.init({
    duration: 1000, // Duración de las animaciones en milisegundos
    once: true,     // Las animaciones se ejecutarán una vez
  })

  const form = useRef();
  
  // Función para enviar el correo
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_2hsux4l', 'template_8e5edcf', form.current, 'wLn8OEDtC7k05D7Pf')
      .then((result) => {
        console.log(result.text);
        alert('Correo enviado exitosamente');
      }, (error) => {
        console.log(error.text);
        alert('Hubo un error al enviar el correo');
      });

    e.target.reset(); // Reiniciar el formulario después de enviarlo
  };

  // Función para enviar el correo
  /*const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zl6z1g4', 'template_6bprdea', form.current, 'IyQifLZkF_zBx0oRz')
      .then((result) => {
        console.log(result.text);
        alert('Correo enviado exitosamente');
      }, (error) => {
        console.log(error.text);
        alert('Hubo un error al enviar el correo');
      });

    e.target.reset(); // Reiniciar el formulario después de enviarlo
  };*/




  return (
    <div>
      {/* --------------------------------------HOME PAGE --------------------------------------*/}


      <section id='home-page' className="home-page">
        {/* Barra de Navegación */}
        <div className="nav-container">
          <nav className="navbar">
            <div className="navbar-brand">
              <img src="/img/LogoBlanco.png" alt="Logo" className="navbar-logo" />
              <span className="navbar-title">GMTI</span>
            </div>
            {/* Lista de enlaces */}
            <ul className={`navbar-links ${isOpen ? 'active' : ''}`} id="navbar-links">
              <li><a href="#home-page" onClick={() => setIsOpen(false)}>Inicio</a></li>
              <li><a href="#about" onClick={() => setIsOpen(false)}>Nosotros</a></li>
              <li><a href="#services" onClick={() => setIsOpen(false)}>Servicios</a></li>
              <li><a href="#clients" onClick={() => setIsOpen(false)}>Clientes</a></li>
              <li><a href="#brands" onClick={() => setIsOpen(false)}>Marcas</a></li>
            </ul>
            <a className="cta-nav-button" href='#contact'>¡Contáctanos!</a>

            {/* Botón de hamburguesa para dispositivos móviles */}
            <div className="hamburger" onClick={toggleMenu}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>


          </nav>
        </div>

        {/* Hero Section */}
        <div className="hero-container">
          <div className="hero-text">
            <h1>Grupo Masstel TI</h1>
            <p>Somos una empresa Peruana con más de 10 años dedicada a la Comercialización, Servicio y Desarrollo de Proyectos basado en Tecnología de la Información en el
              mercado local. Algunos de los servicios que ofrecemos son la venta y alquiler de equipos de computo, alquiler de impresoras e impresión a volumen,
              alquiler de servidores y mucho más.</p>
            <a className="cta-nav-button-1" href='#about'>¡Conoce más sobre nosotros!!</a>
          </div>
          <div className="hero-image">
            <img
              src="/img/2.png"
              alt="Illustration of person working on laptop"
            />
          </div>
        </div>
      </section>




      {/* Sección de las Cards */}
      <section id="statistics" className="statistics-section text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="stat-card">
                <i className="fas fa-users"></i>
                <h3>+70 clientes atendidos</h3>
              </div>
            </div>
            <div className="col-md-4">
              <div className="stat-card">
                <i className="fas fa-briefcase"></i>
                <h3>+10 años de experiencia</h3>
              </div>
            </div>
            <div className="col-md-4">
              <div className="stat-card">
                <i className="fas fa-desktop"></i>
                <h3>+1000 equipos alquilados</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------------------------------SOBRE NOSOTROS --------------------------------------*/}

      <section id='about' className="company-info-section">
        <div className="container" data-aos="fade-up">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <h2>Somos Grupo Masstel TI S.A.C.</h2>
              <p>
                Grupo Masstel TI S.A.C. es una empresa líder en soluciones tecnológicas,
                especializada en brindar servicios de venta, alquiler de equipos
                tecnológicos, y consultoría en sistemas de información. Con más de 10 años de
                experiencia en el mercado, nos hemos consolidado como un referente en
                la industria, destacándonos por nuestra innovación, calidad de
                servicio, y compromiso con nuestros clientes.
              </p>
              <p>
                Nuestro equipo altamente calificado se dedica a ofrecer soluciones que
                optimizan los procesos de negocio, garantizando eficiencia y
                competitividad para nuestros clientes en un entorno cada vez más
                digitalizado.
              </p>
              <a href="#about-us" className="btn btn-primary">¡Descubre más!</a>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/img/banner/7.jpg" // Reemplaza esta ruta con la ruta correcta de la imagen
                alt="Mapa de la empresa"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="about-us" className="about-us-section">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-md-6">
              <h2>Conoce nuestros diferenciadores</h2>
              <p>Nuestros socios nos escogen por marcar la diferencia en nuestros servicios, con alto impacto sobre sus objetivos.</p>
            </div>
            <div className="col-md-6">
              <div className="card mb-3">
                <div className="card-body">
                  <i className="fas fa-bullseye"></i>
                  <div>
                    <h5 className="card-title">Misión</h5>
                    <p className="card-text">Brindar a las empresas soluciones que cumplan con sus necesidades y mejoras
                      en la implementación de nuevas tecnologías de información para que les permita
                      maximizar la competitividad de su compañía.</p>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <i className="fas fa-eye"></i>
                  <div>
                    <h5 className="card-title">Visión</h5>
                    <p className="card-text">Ser los proveedores tecnológicos más rentable, querido y respetado de nuestro mercado,
                      contribuyendo con el crecimiento y progreso de las empresas socias.</p>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <i className="fas fa-handshake"></i>
                  <div>
                    <h5 className="card-title">Valores</h5>
                    <p className="card-text">Brindar un servicio de buena calidad mostrando los principios de
                      Confianza, Innovación, Integridad, Compromiso, Sostenibilidad, Honestidad, Pasión,
                      Transparencia y Trabajo en equipo.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* --------------------------------------SERVICIOS --------------------------------------*/}

      <section id='services' className="services-section">
        <div className="services-container" data-aos="fade-up">
          <h2 className="services-title">Nuestros Servicios</h2>
          <div className="services-grid">
            <div className="service-card">
              <img src="/img/servicios/arrenda2.jpeg" alt="Icono Servicio 1" className="service-icon" />
              <h1>Arrendamiento de equipos de computo</h1>
              <p>Servicio para el uso temporal de computadoras sin adquirir su propiedad, pagando una precio mensual según las características de cada equipo.</p>
            </div>
            <div className="service-card">
              <img src="/img/servicios/arrenda3.png" alt="Icono Servicio 2" className="service-icon" />
              <h1>Arrendamiento de impresoras</h1>
              <p>Alquiler de impresoras por un período determinado, evitando la compra directa del equipo.</p>
            </div>
            <div className="service-card">
              <img src="/img/servicios/mantenimiento.jpg" alt="Icono Servicio 3" className="service-icon" />
              <h1>Mantenimiento de equipos informáticos</h1>
              <p>Servicio que garantiza el funcionamiento óptimo de los equipos informáticos, previniendo o corrigiendo fallos según las indicaciones brindadas.</p>
            </div>
            <div className="service-card">
              <img src="/img/servicios/digi2.jpeg" alt="Icono Servicio 4" className="service-icon" />
              <h1>Digitalización </h1>
              <p>Servicio para convertir documentos físicos en formato digital para su almacenamiento y gestión electrónica.</p>
            </div>

            <div className="service-card">
              <img src="/img/servicios/torres.jpg" alt="Icono Servicio 1" className="service-icon" />
              <h1>Mantenimiento de radio enlace</h1>
              <p>Servicio que asegura la correcta operación de sistemas de comunicación inalámbrica entre dos puntos.</p>
            </div>
            <div className="service-card">
              <img src="/img/servicios/cupo.png" alt="Icono Servicio 2" className="service-icon" />
              <h1>Servicio de impresión de cuponera</h1>
              <p>Servicio de impresión de cupones o talonarios utilizados para promociones, descuentos o gestión de servicio.</p>
            </div>
            <div className="service-card">
              <img src="/img/servicios/web.jpg" alt="Icono Servicio 3" className="service-icon" />
              <h1>Desarrollo de páginas web</h1>
              <p>Servicio que incluye el diseño, desarrollo e implementación de sitios web según los requerimientos específicos del cliente.</p>
            </div>
            <div className="service-card">
              <img src="/img/servicios/comp.jpeg" alt="Icono Servicio 4" className="service-icon" />
              <h1>Venta de equipos y componentes de cómputo</h1>
              <p>Provisión de laptops, computadoras completas y componentes para equipos de cómputo, tales como procesadores, memorias, discos duros y tarjetas </p>
            </div>
          </div>


        </div>

      </section>


      {/* --------------------------------------CLIENTES --------------------------------------*/}

      <section id="clients" className="clients-section py-5">
        <div className="container" data-aos="fade-up">
          <h2 className="text-center mb-4">Clientes</h2>
          <div className="row text-center">
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/ves.png" alt="Cliente 1" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/sanborja.png" alt="Cliente 2" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/surquillo.png" alt="Cliente 3" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/mira.png" alt="Cliente 4" className="client-logo img-fluid" />
              </div>
            </div>


            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/barranco.jfif" alt="Cliente 1" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/lince.png" alt="Cliente 2" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/comas.jpg" alt="Cliente 3" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/isidro.jfif" alt="Cliente 4" className="client-logo img-fluid" />
              </div>
            </div>


            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/smp.png" alt="Cliente 1" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/inde.png" alt="Cliente 2" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/molina.png" alt="Cliente 3" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/pacha.png" alt="Cliente 4" className="client-logo img-fluid" />
              </div>
            </div>

            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/17.png" alt="Cliente 1" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/18.png" alt="Cliente 2" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/19.png" alt="Cliente 3" className="client-logo img-fluid" />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="client-logo-wrapper">
                <img src="/img/clientes/20.png" alt="Cliente 4" className="client-logo img-fluid" />
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* --------------------------------------MARCAS --------------------------------------*/}

      <section id='brands' className="brands-section">
        <h2 className="text-center mb-4" data-aos="fade-up">Marcas</h2>
        <div className="brands-slider" data-aos="fade-up">
          <div className="brands-track">
            {/* Primer set de imágenes */}
            <div className="brand-logo"><img src="img/marcas/microsoft.png" alt="Microsoft" /></div>
            <div className="brand-logo"><img src="img/marcas/epson.png" alt="Epson" /></div>
            <div className="brand-logo"><img src="img/marcas/intcomex.png" alt="Intcomex" /></div>
            <div className="brand-logo"><img src="img/marcas/lenovo.png" alt="Lenovo" /></div>
            <div className="brand-logo"><img src="img/marcas/hikvision.png" alt="Hikvision" /></div>
            <div className="brand-logo"><img src="img/marcas/hp.png" alt="Lenovo" /></div>
            <div className="brand-logo"><img src="img/marcas/adua.png" alt="Hikvision" /></div>

            {/* Segundo set de imágenes (idéntico al primero) */}
            <div className="brand-logo"><img src="img/marcas/microsoft.png" alt="Microsoft" /></div>
            <div className="brand-logo"><img src="img/marcas/epson.png" alt="Epson" /></div>
            <div className="brand-logo"><img src="img/marcas/intcomex.png" alt="Intcomex" /></div>
            <div className="brand-logo"><img src="img/marcas/lenovo.png" alt="Lenovo" /></div>
            <div className="brand-logo"><img src="img/marcas/hikvision.png" alt="Hikvision" /></div>
            <div className="brand-logo"><img src="img/marcas/hp.png" alt="Lenovo" /></div>
            <div className="brand-logo"><img src="img/marcas/adua.png" alt="Hikvision" /></div>
          </div>
        </div>
      </section>

      {/* --------------------------------------CONTACTO --------------------------------------*/}

      {/* --------------------------------------CONTACTO --------------------------------------*/}
      <section id="contact" className="contact-section">
        <div className="container" data-aos="fade-up">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h2 className="section-title">¡Contáctanos!</h2>
              <p className="section-description">
                ¿Tienes alguna pregunta o necesitas más información? ¡Nos encantaría saber de ti!
                Completa el siguiente formulario y nos pondremos en contacto contigo lo antes posible.
              </p>
            </div>
          </div>

          <div className="row">
            {/* Información de contacto a la izquierda */}
            <div className="col-md-5">
              <div className="contact-info">
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="card contact-card">
                      <div className="card-body text-center">
                        <i className="fas fa-map-marker-alt fa-2x mb-2"></i>
                        <h5 className="card-title">DIRECCIÓN</h5>
                        <p className="card-text">Jr. Fray Luis de León 515 Int. 204  San Borja</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="card contact-card">
                      <div className="card-body text-center">
                        <i className="fas fa-phone fa-2x mb-2"></i>
                        <h5 className="card-title">TELÉFONO</h5>
                        <p className="card-text">+51 981 089 304</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="card contact-card">
                      <div className="card-body text-center">
                        <i className="fas fa-envelope fa-2x mb-2"></i>
                        <h5 className="card-title">EMAIL</h5>
                        <p className="card-text">proyectos@grupomasstelti.com</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="card contact-card">
                      <div className="card-body text-center">
                        <i className="fas fa-clock fa-2x mb-2"></i>
                        <h5 className="card-title">HORARIO DE OFICINA</h5>
                        <p className="card-text">Lun-Vie: 9 a.m a 5 p.m</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Formulario de contacto a la derecha */}
            <div className="col-md-7">
              <form ref={form} onSubmit={sendEmail} className="contact-form">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="text"
                      name="user_name"
                      className="form-control"
                      placeholder="Nombre Completo"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="email"
                      name="user_email"
                      className="form-control"
                      placeholder="Correo Electrónico"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="tel"
                      name="user_phone"
                      className="form-control"
                      placeholder="Celular"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Asunto"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <textarea
                      name="message"
                      className="form-control"
                      rows="5"
                      placeholder="Mensaje"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Enviar Mensaje
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>



      {/* -------------------------------------- FOOTER --------------------------------------*/}

      <footer className="bg-dark text-white text-center py-3">
        <div className="container">
          <div className="row">

          </div>
        </div>
        <div className="text-center mt-4">
          <p>&copy; 2024 Grupo Masstel TI S.A.C. Todos los derechos reservados.</p>
        </div>

      </footer>
    </div>
  );
}

export default App;


